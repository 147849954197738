import ies_logo from '../../assets/unileao/logo-branca.png';

export const light = {
    theme_name: "light",
    general: {
        system_name: "SIA-Audiovisual",
        ies_name: "Unileão",
        ies_logo
    },
    mensures: {
        nav_bar_height: "91px"
    },
    colors: {
        background: '#F5F5F5',
        text_secondary: '#263238',
        text_primary: '#212121'
    },
    navBar: {
        backgroundColor: 'linear-gradient(-45deg, #001E47, #001E47)'
    },
    button_colors: {
        success: '#001E47',
        warning: '#FBC02D',
        danger: '#D32F2F',
        primary: '#001E47'
    },
    login: {
        backgroundColor: 'linear-gradient(-45deg, #0D47A1, #2196F3)'
    }
}