import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 10px;
    background-color: #001E47;

    #logo-branca{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;
        
        img{
            width: 100%;
            max-width: 390px;
            height: 116px;
        }
    }
    
    #form{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        position: relative;
     

        width: 100%;
        max-width: 390px;
        height: 466px;

        background-color: #E8F1F2;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-radius: 8px;

        h1{
            margin-top: 10%;
            margin-bottom: -10px;
            text-align: center;
            font-size: 36px;
            font-weight: 300;
        }

        form{
            width: 100%;
            height: 400px;

            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            padding: 12px;

            .input-login-form{
                width: 100%;
                margin-bottom: 10px;
                font-size: 14
            }

            #login-user, #login-password{
                width: 100%;
                height: 50px;
                border-radius: 4px;
                margin: 10px 0;
                border: 0;
                padding-left: 10px;
                background-color: #FFFFFF;
            }

            #login-user, #login-password:focus{
                outline: 0;
            }
        }
       
    } 

`;