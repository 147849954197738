import styled from "styled-components";

export const Container = styled.div`
  background-color: #f5f5f5;

  flex-grow: 1;
  width: 100%;
  padding: 10px 10px;

  display: flex;
  flex-direction: column;
  align-items: center;

  #basic-addon2 {
    background-color: #29b6f6;
    cursor: pointer;
    i {
      color: #ffffff;
    }
  }
  .checkbox{
   width: 100%; 
   max-width: 1024px;
   margin-bottom: 30px;
   margin-top: 20px;
   display: flex;
   justify-content: center;
   align-items: center;

   input{
    margin-right: 5px;
   }
   checkbox_div{
    width:30px;
   }
   span{
    margin-right: 25px;
    font-size: 18px;
   }
  }

  .lista-reservas {
    width: 100%;
    max-width: 1024px;
    height: auto;

    display: flex;
    flex-flow: row wrap;

  }
  .conteudo {
    width: 100%;
    border-radius: 4px;
    padding: 5px 0;
    background-color: #fafafa;
  }

  .button-Montado {
    background-color: #26a69a;
    border: 0;
    margin-right: 10px;
  }


  @media screen and (max-width: 967px) {
    .lista-reservas {
      justify-content: center;
      align-items: center;
      margin-right: 10px;
    }

  }

  @media screen and (max-width: 703px) {
    .checkbox{
      padding: 2%;
      flex-wrap: wrap;
      justify-content: left;
      input{
       margin-right: 5px;
      }
      checkbox_div{
       width:30px;
      
      }
      span{
       margin-right: 25px;
       font-size: 18px;
      }
     }
  }
`;
