import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 1024px;
  flex-grow: 1;

  background-color: #fff;

  margin-top: 10px;
  padding: 10px 15px;
  border-radius: 4px;

  .pesquisa {
    width: 100%;
    padding-top: 10px;

    h2 {
      font-size: 18px;
      line-height: 1vh;
    }

   
    #input-pesquisa {
      border: 1px solid #cfd8dc;
    }
  }
`;
