import { ReactNode } from "react";
import { Container } from "./styles";

interface IHeaderPagev2Props {
  title: string;
  children?: ReactNode;
}

const HeaderPage = ({ children, title }: IHeaderPagev2Props) => {

  return (
    <Container>
      <div id="title">
        <span>{title}</span>
      </div>

      <div id="handle">
        {children}
      </div>
    </Container>
  );
};

export { HeaderPage };
