import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 1024px;
  min-height: 86px;

  background: #FFF;
  border-radius: 0.35rem;

  margin-top: 10px;
  padding: 10px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  #title{
    span{
      font-size: 2.5rem;
      font-weight: 400;
    }
  }

  #handle{
   

    button{
      width: 198px;
      max-height: 40px;
      background-color: #26a69a;
      margin-top: 5px;
      font-size: 18px;
      border: 0;
    }
  }

  @media screen and (max-width: 417px) {
    flex-direction: column !important;
    #handle, #title{
      width: 100%;
    }

    #handle{
      button{
        width: 100% !important;
      }
    }
  }
`;
