import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import ContextProvider from './context/ContextProvider';
import { ThemeProvider } from 'styled-components';
import { light } from './styles/themes/light';
import GlobalStyle from './styles/global';
import 'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={light}>
      <BrowserRouter>
        <ContextProvider>
          <App />
          <GlobalStyle />
        </ContextProvider>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

