import { AxiosError } from 'axios';
import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import Swal from 'sweetalert2';
import { api } from '../services/ApiService';

export enum EUserRole {
    'DTI' = 'DTI',
    'Professor/Colaborador' = 'Professor/Colaborador',
    'Administrador' = "Administrador"
}

export interface IUser {
    id?:string;
    codusuario: string;
    name: string;
    email: string;
    role: EUserRole;
}

interface IAppContext {
    user: IUser | null;
    setUser: (user: IUser | null) => void;
    handleLogin: (codusuario: string, password: string) => Promise<void>;
    handleLogOut: () => void;
}

interface ILoginResponseData {
    user_info: {
        codusuario: string;
        name: string;
        email: string;
        role: EUserRole;
    },
    token: {
        access_token: string;
        expires_in: Date;
    }
}

export const AppContext = createContext({} as IAppContext);

interface IContextProviderProps {
    children: ReactNode;
}

const ContextProvider = (props: IContextProviderProps) => {

    const [user, setUser] = useState<IUser | null>(null);
    const theme = useTheme();
    const navigator = useNavigate();

    async function handleLogin(codusuario: string, password: string) {
        try {
            const result = await api.post<ILoginResponseData>("/session", {
                codusuario,
                password
            });

            setUser({
                codusuario: result.data.user_info.codusuario,
                name: result.data.user_info.name,
                email: result.data.user_info.email,
                role: result.data.user_info.role
            });

            sessionStorage.setItem(String(process.env.REACT_APP_SESSION_KEY), result.data.token.access_token);

            navigator("/home");
        } catch (err: AxiosError | any) {

            Swal.fire({
                toast: true,
                icon: "warning",
                title: "Atenção!",
                text: err.response ? err.response.data.error : "Falha ao tentar realizar login, por favor tente novamente.\nSe o erro persistir contate o administrador do sistema.",
                confirmButtonColor: theme.button_colors.primary,
                confirmButtonText: "Aceitar"
            })
        }
    }

    function handleLogOut() {
        setUser(null);
        sessionStorage.removeItem(String(process.env.REACT_APP_SESSION_KEY));
        navigator("/", { replace: true });
    }

    return (
        <AppContext.Provider value={
            {
                user,
                setUser,
                handleLogin,
                handleLogOut
            } as IAppContext
        }>
            {props.children}
        </AppContext.Provider>
    );
}

export default ContextProvider;