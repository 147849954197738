import { AxiosError } from "axios";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import Swal from "sweetalert2";
import { isTemplateExpression } from "typescript";
import { CardReservation } from "../../../components/CardReservation";
import { ContainerPesquisa } from "../../../components/ContainerPesquisa";
import { HeaderPage } from "../../../components/HeaderPage";
import { AppContext } from "../../../context/ContextProvider";
import { api } from "../../../services/ApiService";
import { IInputsReserva, IReserve } from "../../NovaReserva";
import { Container } from "./styles";

const HomeColaborador: React.FC = () => {
  const historyNavigator = useNavigate();

  const [reservas, setReservas] = useState<IReserve[]>([]);
  const [ischecked, setIschecked] = useState<boolean>(false)
  const [filtro, setFiltro] = useState("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const theme = useTheme();
  const { user } = useContext(AppContext);

  async function getReserva() {
    try {
      const result = await api.get(`/reservas/all${searchTerm !== "" ? "?term=" + searchTerm : ""}`);
      const reservas: IReserve[] = result.data.map((item: IReserve) => {
        const isMount = item.times.some((s: any) => s.isMounted == true);
        const isAllMount = item.times.every((e: any) => e.isMounted == true);

        item.status = "Aguardando";
        item.status = (isAllMount === true && item.isFinished === true) ? "Finalizado" : item.status;
        item.status = (isAllMount === false && isMount === true) ? "Em montagem" : item.status;
        item.status = (isAllMount === true && item.isFinished === false) ? "Montado" : item.status;

        return item;
      })

      setReservas(reservas.sort((prev, next)=>{
        if(prev.num_reserve > next.num_reserve) return -1
        if(prev.num_reserve < next.num_reserve) return 1
        return 0
      }));

    } catch (err: AxiosError | any) {
      Swal.fire({
        title: "Atenção!",
        icon: "warning",
        text: err.response ? err.response.data.error : "Erro desconhecido. Por favor contate o administrador do sistema.",
        confirmButtonColor: theme.button_colors.primary,
        toast: true
      })
    }
  }

  function filtro_status() {
    setIschecked(!ischecked)

  }


  function dataBDFormatar(date: any) {
    var strData = dayjs(date).format('DD/MM/YYYY')
    return strData;
  }

  useEffect(() => {
    getReserva();
  }, [])

  return (
    <Container>
      <HeaderPage title="Reservas">
        <Button id="button" onClick={() => historyNavigator("/nova-reserva")}>
          Nova reserva <i className="bi bi-plus-circle-dotted"></i>
        </Button>
      </HeaderPage>
      <ContainerPesquisa title={"Pesquisar reserva"} haveresearch>
        <InputGroup className="mb-3">
          <Form.Control
            id="input-pesquisa"
            placeholder="Digite algo para procurar..."
            onChange={(event) => {
              setSearchTerm(event.target.value)
            }}
            onKeyDown={event => {
              if (event.key === "Enter") {
                getReserva()
              }
            }}
          />
          <InputGroup.Text id="basic-addon2">
            <i className="bi bi-search"></i>
          </InputGroup.Text>
        </InputGroup>
        <div className='checkbox'>

          <input type="radio" defaultChecked value={filtro} checked={filtro === ''} onChange={() => { setFiltro(""); filtro_status() }} /><span>Todos</span>
          <input type="radio" value={filtro} checked={filtro === 'Aguardando'} onChange={(ev) => { setFiltro('Aguardando'); filtro_status() }} /><span>Aguardando</span>
          <input type="radio" value={filtro} checked={filtro === 'Em montagem'} onChange={(ev) => { setFiltro('Em montagem'); filtro_status() }} /><span>Em montagem</span>
          <input type="radio" value={filtro} checked={filtro === 'Montado'} onChange={(ev) => { setFiltro('Montado'); filtro_status() }} /><span>Montado</span>
          <input type="radio" value={filtro} checked={filtro === 'Finalizado'} onChange={(ev) => { setFiltro('Finalizado'); filtro_status() }} /><span>Finalizado</span>


        </div>
        <div className="lista-reservas">


          {
            reservas.length > 0 ?
              reservas.filter((item) =>
              {
                if(filtro === "") return true;

                if(item.status){
                  return item.status === filtro
                }

                return false
              }
              ).map((item, idx) => {
                  return (
                    <CardReservation key={idx}
                      idReserva={item.id}
                      numreserva={item.num_reserve}
                      local={item.local.name}
                      times={item.times}
                      campus={item.local.campus}
                      equipamentos={item.equipamentos}
                      descricao={item.description}
                      status={item.status}
                      finalizado={item.isFinished}
                      onReserveUpdated={getReserva}
                      coduser={item.codusuario}
                      name={item.nameUser}
                      data_reserva={dataBDFormatar(item.data)}
                      data_finalizacao={item.updated_at}
                    />
                  )
              })
              :
              <span>Nenhuma reserva até o momento.</span>
          }
        </div>
      </ContainerPesquisa>
    </Container>
  );
};

export { HomeColaborador };
