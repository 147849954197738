import { AxiosError } from "axios";
import { url } from "inspector";
import React, { useContext, useEffect, useState } from "react";
import { Accordion, Button, Form, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useTheme } from "styled-components";
import Swal from "sweetalert2";
import { CardListaItens } from "../../components/CardListaItens";
import { ContainerPesquisa } from "../../components/ContainerPesquisa";
import { HeaderPage } from "../../components/HeaderPage";
import { MainContainer } from "../../components/MainContainer";
import { ModalCadastro } from "../../components/ModalCadastro";
import { api } from "../../services/ApiService";
import { Container } from "./styles";

export interface IInputsCadastroEquipamentos {
  equipamento: any;
  id: string;
  name: string;
  quantity: number;
  campus: string;
}

const Equipamento = () => {
  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
    getValues,
    reset: resetForm
  } = useForm<IInputsCadastroEquipamentos>();
  const theme = useTheme();
  const [equipamentos, setEquipamentos] = useState<IInputsCadastroEquipamentos[]>([]);
  const [equipamentoFiltro, setEquipamentiFiltro] = useState("");

  const [isModalVisible, setIsModalVisible] = useState(false);
  const showCadastroEquipamentos = () => setIsModalVisible(!isModalVisible);

  function handleUpdate(equipamento: IInputsCadastroEquipamentos){
    setValue("id", equipamento.id || "");
    setValue("name", equipamento.name);
    setValue("quantity", equipamento.quantity);
    setValue("campus", equipamento.campus);
    showCadastroEquipamentos();
  }
  
  async function onSubmit(data: IInputsCadastroEquipamentos) {
    
    let request_params = {
      success_message: (!data.id? "Cadastro realizado com sucesso." : "Cadastro atualizado com sucesso"),
      url: (!data.id ? "/equipamento/novo" : "/equipamento/atualizar")       
    }
    try{
      
      const result = await api.post(request_params.url, {
        ...data
      })

      Swal.fire({
        toast: true,
        icon: "success",
        title: "Feito!",
        text: request_params.success_message,
        confirmButtonText: "Aceitar",
        confirmButtonColor: theme.button_colors.primary,
      });

      resetForm()
      getEquipamneto()
     {request_params.success_message == "Cadastro atualizado com sucesso"? showCadastroEquipamentos() : <></>}
    } catch (err: AxiosError | any) {
      Swal.fire({
        toast: true,
        icon: "warning",
        title: "Atenção!",
        text: err.response ? err.response.data.error : "Falha ao tentar comunicar-se com o servidor, por favor tente novamente, se o erro persistir contate o administrador do sistema.",
        confirmButtonText: "Aceitar",
        confirmButtonColor: theme.button_colors.primary,
      });
    }
  }

  async function getEquipamneto(){
    try{
      const result = await api.get("/equipamento/all");
      setEquipamentos(result.data)
    } catch (err: AxiosError | any) {
      Swal.fire({
        title: "Atenção!",
        icon: "warning",
        text: err.response ? err.response.data.error : "Erro desconhecido. Por favor contate o administrador do sistema.",
        confirmButtonColor: theme.button_colors.primary,
        toast: true
      })
    }

  }

  async function deletarEquipamento(id_equipamento: string){
    let isloading = false;

    await Swal.fire({
      title: "Atenção!",
      icon: "question",
      text: `Tem certeza que deseja excluir esse equipamento?`,
      confirmButtonColor: theme.button_colors.primary,
      confirmButtonText: "Sim",
      denyButtonText: "Não",
      denyButtonColor: theme.button_colors.danger,
      showDenyButton: true,
      toast: true,
      preConfirm: () => executeDelete(),
      showLoaderOnConfirm: isloading
    })

  async function executeDelete(){
     isloading= true;
    try{
    
      await api.delete(`equipamento/${id_equipamento}/delete`);
      
      Swal.fire({
        title: "Feito!",
        icon: "success",
        text: "Equipamento apagado com sucesso",
        confirmButtonColor: theme.button_colors.primary,
        confirmButtonText: "Aceitar",
        toast: true,
      })

      getEquipamneto();
    } catch (err: AxiosError | any) {
        Swal.fire({
          title: "Atenção!",
          icon: "warning",
          text: err.response ? "Erro! Equipamento está vinculado a uma reserva." : "Erro desconhecido. Por favor contate o administrador do sistema.",
          confirmButtonColor: theme.button_colors.primary,
          toast: true
        })
         isloading= false;
      }
      }
  }


  useEffect(() => {
    getEquipamneto();
 }, [])



  return (
    <MainContainer>
      <Container>
        <HeaderPage title="Equipamentos">
          <Button id="button" onClick={showCadastroEquipamentos}>
            Novo equipamento <i className="bi bi-plus-circle-dotted"></i>
          </Button>
        </HeaderPage>
        <ContainerPesquisa title={"Pesquisar equipamento"} haveresearch>
          <InputGroup className="mb-3">
            <Form.Control
              id="input-pesquisa"
              placeholder="Digite algo para procurar..."
              value={equipamentoFiltro}
              onChange={(ev) => setEquipamentiFiltro(ev.target.value)}
            />
            <InputGroup.Text id="basic-addon2">
              <i className="bi bi-search"></i>
            </InputGroup.Text>
          </InputGroup>
          <div className="lista">
          {equipamentos.length > 0 ?
            equipamentos.filter((item) =>
            item.name.includes(equipamentoFiltro) || item.campus.toLowerCase().includes(equipamentoFiltro.toLowerCase())
            )
            .map((item) => {
              return <CardListaItens
                  key={item.id}
                  titulo1="Equipamento:"
                  titulo2="Quantidade:"
                  titulo3="Campus:"
                  texto1={item.name}
                  texto2={item.quantity}
                  texto3={item.campus}
                >
                  <Button className="button-apagar" variant="outline-danger"
                  onClick={()=> deletarEquipamento(item.id)}>
                    Apagar <i className="bi bi-trash-fill"></i>
                  </Button>
                  <Button className="button-editar" variant="warning"
                  onClick={()=> handleUpdate(item)}>
                    Editar <i className="bi bi-pencil-square"></i>
                  </Button>
                </CardListaItens>
            })
            : <span>Nenhum equipamento cadastrado até o momento.</span>
          }
            </div>
        </ContainerPesquisa>

        {isModalVisible && (
          <ModalCadastro title={!getValues("id") ? "Cadastrar Equipamento" : "Atualizar Equipamento"}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  <strong>Equipamento</strong>
                </Form.Label>
                <Form.Control
                  type="text"
                  isInvalid={errors.name ? true : false}
                  {...register("name", {
                    required: "Este campo é obrigatório.",
                  })}
                />
                {errors.name && (
                  <span className="label-error">{errors.name.message}</span>
                )}
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  <strong>Quantidade</strong>
                </Form.Label>
                <Form.Control
                  type="number"
                  isInvalid={errors.quantity ? true : false}
                  {...register("quantity", {
                    required: "Este campo é obrigatório.",
                  })}
                />
                {errors.quantity && (
                  <span className="label-error">{errors.quantity.message}</span>
                )}
              </Form.Group>

              <div>
                <Form.Group>
                  <Form.Label>
                    <strong>Campus</strong>
                  </Form.Label>
                  <Form.Select
                    isInvalid={errors.campus ? true : false}
                    {...register("campus", {
                      required: "Este campo é obrigatório.",
                    })}
                  >
                    <option disabled></option>
                    <option>Lagoa Seca</option>
                    <option>Saúde</option>
                    <option>Crajubar</option>
                  </Form.Select>
                  {errors.campus && (
                    <span className="label-error">{errors.campus.message}</span>
                  )}
                </Form.Group>
              </div>

              <div className="button">
                <Button
                  className="button-cancelar"
                  variant="outline-danger"
                  onClick={()=>{
                    resetForm()
                    showCadastroEquipamentos()
                  }}
                >
                  Cancelar <i className="bi bi-x-circle"></i>
                </Button>
                <Button className="button-cadastrar" type="submit">
                  {!getValues("id") ? "Cadastrar" : "Atualizar"} <i className="bi bi-check-circle"></i>
                </Button>
              </div>
            </form>
          </ModalCadastro>
        )}
      </Container>
    </MainContainer>
  );
};

export { Equipamento };
