import { ReactNode } from "react";
import { Accordion, Button } from "react-bootstrap";
import { Container } from "./styles";

interface ICardItens {
  titulo1: string;
  titulo2: string;
  titulo3?: string;
  texto1: string;
  texto2: string | number;
  texto3?: string;
  children?: ReactNode;
}

const CardListaItens = ({
  children,
  titulo1,
  titulo2,
  titulo3,
  texto1,
  texto2,
  texto3
}: ICardItens) => {
  return (
    <Container>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <div className="info-local">
              <div className="row titulo">
                <div className="col">{titulo1}</div>
                <div className="col">{titulo2}</div>
                <div className="col">{titulo3}</div>
              </div>
              <div className="row">
                <div className="col">{texto1}</div>
                <div className="col">{texto2}</div>
                <div className="col">{texto3}</div>
              </div>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className="button_card">{children}</div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
};

export { CardListaItens };
